<template>
  <div class="cover">
    <div class="logo">
      <img src="https://statics.terabits.cn/mainimg/child.png" alt class="logoimg" />
      婴幼儿沐浴场所消毒卫生监管平台
    </div>
    <div class="loginmain">
      <div class="loginl"></div>
      <div class="loginr">
        <div class="accounttitle">账号登录</div>
        <el-form :model="loginForm" :rules="rules" ref="loginForm" label-width="0" class="demo-ruleForm">
          <el-form-item prop="userName">
            <el-input v-model="loginForm.userName" placeholder="请输入用户名" class="userNameinput">
              <i slot="prefix">
                <i class="el-icon-user"></i>
              </i>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input type="password" v-model="loginForm.password" placeholder="请输入密码" class="passinput">
              <i slot="prefix">
                <i class="el-icon-lock"></i>
              </i>
            </el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" :loading="loginLoading" @click="submitForm('loginForm')" class="submitbtn">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="bombtn">
      平台服务商：&nbsp;&nbsp;
      <a href="http://www.terabits.cn/" target="_blank" rel="noopener noreferrer">杭州钛比科技有限公司</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;联系电话：&nbsp;&nbsp;0571-87755736
    </div>
    <div class="bombtns">浙ICP备16003817号-1&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;网站标识码：3610782</div>
    <div class="bombtns">
      <img />
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602007808" target="_blank" rel="noopener noreferrer">浙公网安备33010602009975号</a>
    </div>
  </div>
</template>
<script>
// import { login, addlogoperate } from "../axios/api.js";
import { infantLogin } from '@/api'
import { setToken } from '@/utils/auth'

export default {
  data () {
    var validateName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("用户名不能为空"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };

    return {
      loginForm: {
        password: "",
        userName: ""
      },
      rules: {
        password: [{ validator: validatePass, trigger: "blur" }],
        userName: [{ validator: validateName, trigger: "blur" }]
      },

      loginLoading: false,
      redirect: undefined
    };
  },
  // watch: {
  //   $route: {
  //     handler: function(route) {
  //       this.redirect = route.query && route.query.redirect;
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    submitForm () {
      // console.log(this.loginForm.userName);
      // this.$router.push("/unit");
      this.$refs.loginForm.validate(valid => {
        // console.log(valid);
        if (valid) {
          // 通过登录获取token
          infantLogin(this.loginForm).then((res) => {
            localStorage.clear()
            setToken(res.data.token)
            this.$store.commit('SET_TOKEN', res.data.token)
            this.$router.push('/infanttv')
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.cover {
  width: 100%;
  height: 100vh;
  background: url("https://statics.terabits.cn/mainimg/background.png") no-repeat bottom
    center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  .logo {
    font-size: 36px;
    font-family: "Alibaba-PuHuiTi1f1b5035d81d0b6";
    color: #2a2a2a;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
  }
  .loginmain {
    width: 800px;
    height: 440px;
    margin: 0 auto;
    border-radius: 5px;
    margin-top: 40px;
    box-shadow: -2px 5px 20px #e2e7e7;
    background: #fafcff;
    .loginl {
      width: 55%;
      height: 440px;
      background: url("https://statics.terabits.cn/mainimg/childback.png") no-repeat
        center center;
      float: left;
    }
    .loginr {
      width: 45%;
      float: right;
      padding: 40px;
      box-sizing: border-box;
      .accounttitle {
        font-size: 18px;
        color: #24abea;
        border-bottom: 2px solid #24abea;
        padding-bottom: 10px;
        margin-bottom: 40px;
        width: 80px;
        text-align: center;
      }
      .submitbtn {
        width: 100%;
        background: #10beb8;
        border: 1px solid #10beb8;
      }
    }
  }
  .bombtn {
    text-align: center;
    margin-top: 40px;
    color: #666666;
    font-size: 14px;
  }
  .bombtns {
    margin-top: 10px;
    color: #666666;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
